.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input {
  border: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  
html,
body,
div {
  margin: 0;
  padding: 0;
  line-height: normal;
  /* font-family: 'Tungsten Rounded'; */
  color: #000000;
  word-wrap: break-word;
}

.App {
  min-width: 1024px !important;
  text-align: left;
}



.alphaBtnDiv {
  display: flex;
  height: auto;
  background-color: transparent;
  margin-left: 10.65%;
}

.alphaBtnDiv p {
  margin-left: 6px;
  margin-top: 6px;
}

.landing-page-body {
  background: #FFBFD9;
  background-image: url("./images/landingPhone.svg"),  url('./images/topleft.svg'), url('./images/bottomright.svg');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 0% 65%, left top, right bottom;
  height: auto;
}

  .navHeader {
    margin: 24px;
    display: flex;
    flex-direction: row;
    background-color: #fff!important;
    justify-content: end;
    width: 100%;
  }

  .navTypography {
    font-family: 'Raleway', sans-serif;
    size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
  }

  .navButton {
    margin-top: 10px;
    border-radius: 4px!important;
    background-color: #2E8FCE!important;
  }

  .alphaBtn {
    background-color: #001E3C!important;
    margin-left: 24px!important;
    border-radius: 0!important;
    border-color: transparent!important;
    height: 35px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140.88%;
  }

  .alphaBtn:disabled {
    opacity:unset!important;
  }

  .title {
    font-family: "Tungsten Rounded";
    color: #001E3C;
    text-align: center;
    font-weight: 700;
    line-height: 60px;
    font-size: 48px;
  }
  
  .text {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #001E3C;
    /* text-align: center; */
  }

  .landing-page-description{
    margin-right: auto;
    margin-left: auto;
    margin-top: 24px;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .subtitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-weight: 700;
    color: #001E3C;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  .phoneFacts{
    display: none!important;
  }
  
  .desktopFacts {
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
  }
  .bdyBtn {
    background-color: #ffff!important;
    color: #2E8FCE!important;
    margin-left: auto!important;
    margin-right:auto!important;
    margin-top: 24px;
    border-color: transparent!important;
    width: 200px;
  }



  .subheader {
    font-family: 'Raleway';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    color: #001E3C;
    text-align: center;
    padding-top: 47.5px;
  }


  @media only screen and (max-width: 1024px) {
    
  }

  @media only screen and (max-width: 600px) {
   
}


