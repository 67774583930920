/* 1920px large screen/desktop */
.opt-out-landing-body {
  background: #00446D;
  min-height: 300px !important;
  max-height: 300px!important;
}


.success-content-container {
  background: #00446D;
  min-height: 595px !important;
  background-image: url("../images/success-graphic.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.deadline-div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  width: 400px;
  height: 25px;
  background-color: #001E3C;
  padding: 6px;
  text-align: center;
}

.successContent {
  padding-left: 40px;
  width: 50%;
}

.successContent p{
  /* Body 2 REG [small & down] */

font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;
/* or 125% */

letter-spacing: 0.01em;

color: #FFFFFF;
}

.successContent Button{
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #000 !important;
}

.opt-out-info {
  background: #2E8FCE;
border-radius: 8px;
text-align: left;
padding: 10px;
color: #FFFFFF;
margin: 10px;
}

.file-size-info {
  background: #FFFFFF;
border-radius: 8px;
text-align: left;
padding: 10px;
color: #000000;
margin: 10px;
}

.dark-btn-whd {  
 
  margin-left:auto!important;
  margin-right:auto!important;
  margin-top: 0px;
  background-color:#00446D !important;
  color: #FFFFFF !important; 
  border-color: transparent!important;
   height: 40px!important;
   font-weight: 600;
}

.file-size-info p {
  color: #000000;
  font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
/* or 150% */

text-align: left;
letter-spacing: 0.01em;
}


.opt-out-info h3 {
  color: #fff;
  font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */

text-align: left;
letter-spacing: 0.01em;
}

.opt-out-info p {
  color: #fff;
  font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
/* or 150% */

text-align: left;
letter-spacing: 0.01em;
}

.document-overview {
  border-left-color: #000000;
border-left-width: 1px;
border-left-style: solid;
padding-left: 10px;
}

.document-overview  h4{
  font-family: Raleway;
 font-size: 16px;
 line-height: 20px;
 }

.document-overview  p{
 font-weight: 600;
 font-family: Raleway;
font-size: 16px;
line-height: 20px;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.sub-nav-container {
  padding-top: 40px;
}

.form-section {

  padding: 20px;
  min-height: 300px;

  background-color: #E5E5E5;
  
}

.file-add-button {
  padding-top: 20px
}


.learn-btn {
  margin: 0 !important;
  text-decoration: underline !important;
  padding: 0 !important;
  border: none !important;
  text-align: left !important;
  background-color:transparent !important;
  color: #FFFFFF!important;
  text-underline-offset: 5px;
}

.form-section h2{

  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  
  letter-spacing: 0.01em;
  

  
  color: #000000;
  
}

.top-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  column-gap: 10px;
}

.hero-title {


  margin-left: 0;

font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 100%;


padding: 40px 0 0 40px;

letter-spacing: 0.01em;

color: #FFFFFF;

width: 50%;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr; 
}

.deadline {
  font-family: "Raleway";
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  /* width: 145px; */
  line-height: 140.88%;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  word-break: keep-all;
}

.title {
  margin-top: 24px;
  color: #FFFFFF;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.01em;    
  /* text-align: center; */
  margin-right: auto;
  margin-left: auto;
  width: 40%;
}

.hero-title {


  margin-left: 0;

font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 100%;


padding: 40px 0 0 40px;

letter-spacing: 0.01em;

color: #FFFFFF;

width: 50%;
}


.description {
  color: #FFFFFF;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.01em;
  margin-top: 12px;
  width: 50%;
  margin-left: 0;
  padding-left: 40px;
}

.sub-text {
  margin:5px;
  color: #FFFFFF;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0.01em;
  margin-top: 12px;
  width: 33%;
  margin-left: 0;
  padding-left: 40px;
}

.signup-btn-div {
  height:auto;
  text-align: left;
  margin-left: 0;
  padding-left: 40px;
}
.signup-btn-bounce {  

  margin-left:auto!important;
  margin-right:auto!important;
  margin-top: 0px;
  background-color: #ffff!important;
  color: #2E8FCE!important; border-color: transparent!important;
   width: 120px!important;
   height: 40px!important;
}

.scroll-div {
   height: auto;
   text-align: center;
}

.scroll-text {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: normal;
   text-align: center;
   letter-spacing: 0.01em;
   color: #FFFFFF;
   padding-top: 64px;
}

.arrow-down-btn {
   border: none!important;
   margin-left: auto!important;
   margin-right: auto!important;
   /* margin-left: 49%; */
   text-decoration: none;
   background-color: transparent!important;
   color: #FFFFFF!important;
}

.alpha-text {
   margin-left: 6px;
   padding-top: 0px;
}

@media only screen and (max-width: 1024px) {
  
  
}

@media only screen and (max-width: 1024px) {
  
}

@media only screen and (max-width: 600px) {
 
  
  @media only screen and (max-width: 320px) {
  
      
}
}