.header {
    background-color: #fff!important;
    width: 100%;
    height: 65px!important;
}

.header-container {
    max-height: 70px;
    background-color: #FFF;
    /* margin: 24px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-logo {
    margin-left: 12%;
    margin-top: 12px;
}

.header-logo :hover {
    cursor:pointer
}

.navTypography-header {
    font-family: 'Raleway', sans-serif;
    size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    display: flex;
    margin-right: 12%;
    margin-top: 8px;
  }

.navButton {
    
}

@media only screen and (max-width: 1024px) {

    .header-logo {
        margin-left: 24px;
        margin-top: 12px
    }
    .navTypography-header {
        font-family: 'Raleway', sans-serif;
        size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000;
        display: flex;
        margin-right: 24px;
        margin-top: 8px;
      }
}



.text-header {
    margin-right: 24px;
    margin-top: 14px;
}
@media only screen and (max-width: 600px) {
    .text-header{
        display: none;
    }
}