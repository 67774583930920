.footer-container {
    background-color: #FFF;
    width: 100%;
    /* margin: 24px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-terms {
    width: fit-content;
    display: flex;
    justify-content: end;
    flex-direction: row;
    margin-top: 24px;
    margin-right: 12%;
}
.footer1{
    margin-top: 60px;
}
.navTypography-footer {
    
    font-size: 12px;
}

.footer-logo {
    margin-top: 24px;
    margin-left: 12%;
}
@media only screen and (max-width: 1024px) {
    .footer-terms {
        width: fit-content;
        display: flex;
        justify-content: end;
        flex-direction: row;
        margin-top: 24px;
        margin-right: 24px;
    }
    .footer-logo {
        margin-top: 24px;
        margin-left: 24px;
    }
}
@media only screen and (max-width: 600px) {
        .navTypography-footer {
            display: flex;
            flex-direction: column;
            font-size: 10px;
            margin-right: 24px;
        }
        
        @media only screen and (max-width: 320px) {
            .footer-container {
                margin-top: 64px;
            }
        }
}